import React from 'react';
import './detail-heading.css'


function DetailHeading(props){
    return(
        <div className="section-for-design">
            <h1 className="section-for-design-heading">
                {props.name}
            </h1>
            <h3 className="section-for-design-heading-small">
                {props.description}
            </h3>
        </div>
    )
}

export default DetailHeading;