export const data = [
  {
    id: 0,
    url: "https://aurangg.com/design/rela",
    name: "Rela - Real Estate",
    isDesign: true,
    isDevelopment: false,
    tags: [
      { text: "Landing Page Redesign" },
      { text: "Real Estate" },
      { text: "Figma" },
    ],
  },
  {
    id: 1,
    url: "https://aurangg.com/design/milestone",
    name: "Milestone SP",
    isDesign: true,
    isDevelopment: false,
    tags: [
      { text: "Landing Page" },
      { text: "Sneakers" },
      { text: "Online Store" },
      { text: "E-commerce" },
      { text: "Redesign" },
      { text: "Insurance" },
    ],
  },
  {
    id: 2,
    url: "https://aurangg.github.io/proxyverse/index.html",
    name: "Proxyverse",
    isDesign: true,
    isDevelopment: true,
    tags: [
      { text: "Proxy" },
      { text: "Servers" },
      { text: "Redesign" },
      { text: "Branding" },
      { text: "Design Systems" },
      { text: "Software Company" },
      { text: "Front End" },
    ],
  },
];
