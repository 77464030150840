// import image1 from '../Images/portfolio-images/acasa.png'
// import image2 from '../Images/portfolio-images/milestone-sp.png'
// import image3 from '../Images/portfolio-images/nyc-app.png'
// import image4 from '../Images/portfolio-images/moneezen.png'
// import image5 from '../Images/portfolio-images/sinecure.png'
// import image6 from '../Images/portfolio-images/flashxchanger.png'
// import image7 from '../Images/portfolio-images/rela.png';
// import image8 from '../Images/portfolio-images/hanna.png';
export const design = [
    // {
    //     id:1,
    //     image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/acasa.webp',
    //     name:"acasa",
    //     color:"#EBF0FF",
    // },
    {
        id:1,
        image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/milestone-sp.webp',
        name:"milestone",
        color:"#FDF0F2",
    },
    {
        id:2,
        image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/hanna.webp',
        name:"hanna",
        color:"#FCC6DC",
    },
    {
        id:3,
        image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/nyc-app.webp',
        name:"nyc",
        color:"#E5E5E5",
    },
    {
        id:4,
        image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/rela.webp',
        name:"rela",
        color:"#C9CFFF"
    }
]
export const frontend = [
    {
        id:1,
        image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/moneezen.webp',
        name:"moneezen",
        color:"#DEDFFF",
    },
    {
        id:2,
        image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/sinecure.webp',
        name:"sinecure",
        color:"#161B21",
    },
    {
        id:3,
        image:'https://cdn.hammza.com/aurangg/Images/portfolio-images/flashxchanger.webp',
        name:"flashxchanger",
        color:"#E8CEFD",
    },
]