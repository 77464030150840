export const design = [
  {
    id: 1,
    name: "NYC App",
    url: "nyc",
    nameDescription: "NYC Tourism",
    mainImage:
      "https://cdn.hammza.com/aurangg/assets/detail/nyc-app/nyc-app-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/nyc-app/nyc-app-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/nyc-app/nyc-app-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/nyc-app/nyc-app-3.webp",
    progressDesign1:
      "https://cdn.hammza.com/aurangg/assets/detail/nyc-app/nyc-app-4-a.webp",
    progressDesign2:
      "https://cdn.hammza.com/aurangg/assets/detail/nyc-app/nyc-app-4-b.webp",
    progressDesign3:
      "https://cdn.hammza.com/aurangg/assets/detail/nyc-app/nyc-app-4-c.svg",
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/nyc-app.webp",
    conclusion:
      "The application design in the end was completed successfully and the client was very happy with the output. The application is currently in development phase.",
    goal: "The goal for NYC Smart App was to create an application where tourists can get all possible facilities, discounts, events and destinations in NYC.",
    designprocess:
      "From gathering inital requirements and the inpsiration for the design from my client, I began creating userflows, user journeys and wireframes of the application. The application is over 60 screens. I designed the structure of the application in such a way that in beginning alone, users can interact with the most popular and important stuff in the application.",
    description:
      "With a strict deadline, I started working with the visual design of the application. The client was looking for a clean, minamlist and a modern interface to match the standards of other applications.",
    color: "rgba(229, 229, 229, 0.7)",
  },
  {
    id: 2,
    name: "Acasa",
    url: "acasa",
    nameDescription: "Moving Vehicles",
    mainImage:
      "https://cdn.hammza.com/aurangg/assets/detail/acasa/acasa-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/acasa/acasa-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/acasa/acasa-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/acasa/acasa-3.webp",
    progressDesign1:
      "https://cdn.hammza.com/aurangg/assets/detail/acasa/acasa-4-a.webp",
    progressDesign2:
      "https://cdn.hammza.com/aurangg/assets/detail/acasa/acasa-4-c.svg",
    progressDesign3:
      "https://cdn.hammza.com/aurangg/assets/detail/acasa/acasa-4-b.webp",
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/milestone.webp",
    conclusion: "Success",
    goal: "Hectic",
    designprocess: "very design",
    description: "Great Work",
    color: "rgba(235, 240, 255, 0.7)",
  },
  {
    id: 3,
    name: "Milestone SP",
    url: "milestone",
    nameDescription: "Premium Sneaker Insurance",
    mainImage:
      "https://cdn.hammza.com/aurangg/assets/detail/milestone/milestone-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/milestone/milestone-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/milestone/milestone-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/milestone/milestone-3.webp",
    progressDesign1:
      "https://cdn.hammza.com/aurangg/assets/detail/milestone/milestone-4-a.webp",
    progressDesign2:
      "https://cdn.hammza.com/aurangg/assets/detail/milestone/milestone-4-b.webp",
    progressDesign3:
      "https://cdn.hammza.com/aurangg/assets/detail/milestone/milestone-4-c.svg",
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/milestone.webp",
    conclusion:
      "The client was happy and completely satisifed with it's lively, modern and premium look and feel as website theme was consistent in the pages of the website.",
    goal: "The goal was to create a platform where people can make Insurance of their high priced sneakers.",
    designprocess:
      "Using sprint design technique, I was able to complete the design. With white and bubblegum red color, we curved out an attractive layout for the website. The User Experience for the website was intended to increase the sales conversion rate. Starting with the Hero Image, I layered out the pricing for the insurance to grab maximum attention of the user. Then we followed with the Pros of having this issurance ending with a contact information.",
    description:
      "From collecting requirements we started figuring out a premium and modern look for the website to match it's goals to all ends. Website held different features like Blogs, Services, Releases and Contact information",
    color: "rgba(253, 240, 242, 0.7)",
  },
  {
    id: 4,
    name: "Rela Real Estate",
    url: "rela",
    nameDescription: "Sell your Real Estate",
    mainImage: "https://cdn.hammza.com/aurangg/assets/detail/rela/rela-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/rela/rela-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/rela/rela-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/rela/rela-3.webp",
    progressDesign1:
      "https://cdn.hammza.com/aurangg/assets/detail/rela/rela-4-a.webp",
    progressDesign2:
      "https://cdn.hammza.com/aurangg/assets/detail/rela/rela-4-c.webp",
    progressDesign3:
      "https://cdn.hammza.com/aurangg/assets/detail/rela/rela-4-b.webp",
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/rela.webp",
    conclusion:
      "The Rela project was a success and Michael was happy with the outcome. We got exactly what we were looking for and it's already in development phase.",
    goal: "Rela Real Estate was a website for selling your premium real estate online. The goal was to design a website that could support different real estates and display their information.",
    designprocess:
      "My client, Michael Higgins, was very interested and actively involved in this project. We helped me by explaining his design goal and together we discussed requirements. The website includes different sections like House Description, House/Room sizes & dimensions, Amenities and 3D tours.",
    description:
      "Rela was an important project, since it meant to work for all the real estate that were meant to be sold online. It has different layouts with animations to enhance the website using experience for niche community.",
    color: "rgba(201, 207, 255, 0.7)",
  },
  {
    id: 4,
    name: "Hanna Landis",
    url: "hanna",
    nameDescription: "Freelance Web Designer | Developer",
    mainImage:
      "https://cdn.hammza.com/aurangg/assets/detail/hanna/hanna-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/hanna/hanna-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/hanna/hanna-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/hanna/hanna-3.webp",
    progressDesign1:
      "https://cdn.hammza.com/aurangg/assets/detail/hanna/hanna-4-a.webp",
    progressDesign2:
      "https://cdn.hammza.com/aurangg/assets/detail/hanna/hanna-4-c.webp",
    progressDesign3:
      "https://cdn.hammza.com/aurangg/assets/detail/hanna/hanna-4-b.svg",
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/rela.webp",
    conclusion:
      "In the end, Hanna was very happy with the outcome of her website design. She got the look she wanted and was super happy to update her portfolio accoridng.",
    goal: "Hanna Landis is a freelance web designer | developer based in Seattle, WA in the United States. She offers services for small business in her community to grow an online presence. Hanna was looking for a revamp as the website was not matching with modern web design.",
    designprocess:
      "In this specific project, Hanna was struggling to showcase her years of experience in her website. I had a requirements gathering session with her, where I looked into feedback from her clients. I went with white as my primary color to increase contrast for different work that will be displayed as her portfolio. To emphasize on her Web development skills, I used famous website builders icon to increase an impact on the user.",
    description:
      "As Hanna is herself a designer and developer, it was a fun project to help her out in new design. She wanted to use her main theme colors and wanted to continue with it. ",
    color: "rgba(252, 198, 220, 0.6)",
  },
];

export const frontend = [
  {
    id: 1,
    name: "Moneezen",
    url: "moneezen",
    nameDescription: "Digital Payments with Bootstrap",
    mainImage:
      "https://cdn.hammza.com/aurangg/assets/detail/moneezen/moneezen-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/moneezen/moneezen-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/moneezen/moneezen-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/moneezen/moneezen-3.webp",
    // progressDesign1:'https://cdn.hammza.com/aurangg/assets/detail/moneezen/moneezen-4-a.webp',
    // progressDesign2:'https://cdn.hammza.com/aurangg/assets/detail/moneezen/moneezen-4-b.svg',
    // progressDesign3:'https://cdn.hammza.com/aurangg/assets/detail/moneezen/moneezen-4-c.webp',
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/moneezen.webp",
    // conclusion:"Success",
    goal: "Moneezen is a France based company that provides Credit card facilities. I was responsible to convert the designs using Embedded JavaScript (EJS) and Bootstrap 4.",
    designprocess:
      "For Moneezen, I used CSS classes to manage responsivness and for adjusting the content to some extent. Moneezen is responsible for all types of devices and includes animations like fading in images and texts.",
    description:
      "The website was strictly limited for using Bootstrap classes with Embedded JavaScript environment. I was also responsible for making it SEO compatible and optimising the code with different techniques like minifying CSS and lazy-loading.",
    color: "rgba(221, 225, 255, 0.7)",
  },
  {
    id: 2,
    name: "Sinecure",
    url: "sinecure",
    nameDescription: "Software Company",
    mainImage:
      "https://cdn.hammza.com/aurangg/assets/detail/sinecure/sinecure-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/sinecure/sinecure-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/sinecure/sinecure-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/sinecure/sinecure-3.webp",
    // progressDesign1:'https://cdn.hammza.com/aurangg/assets/detail/sinecure/sinecure-4-a.webp',
    // progressDesign2:'https://cdn.hammza.com/aurangg/assets/detail/sinecure/sinecure-4-c.webp',
    // progressDesign3:'https://cdn.hammza.com/aurangg/assets/detail/sinecure/sinecure-4-b.webp',
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/sinecure.webp",
    // conclusion:"Success",
    goal: "Sinecure is a software solution company that provides services and solutions to clients from all over the world.",
    designprocess:
      "Sinecure website is completely responsive for all devices, including iPad, iPhones, Android and Tablets. I used Animsta and Anime.Js for animations in the website. All the animations are controlled for the viewport, instead of infinte animation.",
    description:
      "Developing Sinecure's website was very challenging since it was completed developed and designed by me. Website is divided into two versions, that are Main website and Admin Panel. Both parts of the project are developed and integrated in ReactJs. I used different libraries like ChartJs, React Router Dom, Lodash, Material UI. The integration with Django APIs were made possible with GraphQL. Animations are used from anime.js and animsta.",
    // color:"rgba(48, 54, 61, 0.7)",
    color: "rgba(19, 19, 19, 0.3)",
  },
  {
    id: 3,
    name: "FlashXChanger",
    url: "flashxchanger",
    nameDescription: "Cryptocurrency and Everything",
    mainImage:
      "https://cdn.hammza.com/aurangg/assets/detail/flashxchanger/flashxchanger-1.webp",
    secondImage:
      "https://cdn.hammza.com/aurangg/assets/detail/flashxchanger/flashxchanger-2a.webp",
    thirdImage:
      "https://cdn.hammza.com/aurangg/assets/detail/flashxchanger/flashxchanger-2b.webp",
    visualDesign:
      "https://cdn.hammza.com/aurangg/assets/detail/flashxchanger/flashxchanger-3.webp",
    // progressDesign1:'https://cdn.hammza.com/aurangg/assets/detail/flashxchanger/flashxchanger-4-a.webp',
    // progressDesign2:'https://cdn.hammza.com/aurangg/assets/detail/flashxchanger/flashxchanger-4-c.webp',
    // progressDesign3:'https://cdn.hammza.com/aurangg/assets/detail/flashxchanger/flashxchanger-4-b.svg',
    technologyImage:
      "https://cdn.hammza.com/aurangg/assets/detail/logos/flashxchanger.webp",
    // conclusion:"Success",
    goal: "FlashXChanger was a cryptocurrency website for a company named, Sapian Captial. The website showcases the ability of the platform by displaying different features and advantages.",
    designprocess:
      "I used CSS animations with Bootstrap in React. The website is responsive for all devices, including iPad, iPhones, Android and Tablets",
    description:
      "This website was decided to be developed in React JS. This website includes JSX Elements from React. I was responsible to convert design to a fully functional and future integratable code.",
    color: "rgba(232, 206, 253, 0.5)",
  },
];

export const blogs = [
  {
    id: 1,
    title: "Beginner React Js RoadMap",
    date: "31 May, 2021",
    description:
      "In this blog, I will guide you through a roadmap of learning ReactJs as a beginner developer.",
    headingAndParagraph: [
      {
        id: 1,
        heading: "What is ReactJS?",
        paragraph:
          "ReactJs is a library of JavaScript, created and maintained by Facebook for richer front end developement. It is mainly javascript and it is super lightweight to support big applications like Facebook to work smoothly.",
        link: "https://reactjs.org/",
      },
      {
        id: 2,
        heading: "From where to start?",
        paragraph:
          "You are required to have basic understanding of HTML, CSS and further understanding of basic JavaScript. ReactJs is built of latest JavaScript framework called ES6 or ECMAScript6. It is super easy and fun to learn",
        link: "https://www.youtube.com/watch?v=WZQc7RUAg18",
      },
      {
        id: 3,
        heading: "Why JS?",
        paragraph:
          "JavaScript started as a scripting language for HTML Web pages in the beginning of it. Later it got powerful over the years and JavaScript can be used anywhere. In FrontEnd, you can use JavaScript as React, Vue or Angular. For Backend and Server Side, you can use NodeJs. For Desktop Applications, you can use Electron JS. And for the Mobile App development, you can use React Native. All of these frameworks and libraries are super light and very easy to use. In the link below, you can find all the tools that are required in any JavaScript field.",
        link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
      },
      {
        id: 4,
        heading: "Where To Practice?",
        paragraph:
          "Once you understand the basics of JavaScript, you can try practicing the basic, medium and advance level problems on HackerRank.",
        link: "https://www.hackerrank.com/",
      },
      {
        id: 5,
        heading: "Key Components of ReactJs for Beginner",
        paragraph:
          "ReactJs consists of the following things. 1) Components. 2) Virtual Dom. 3) Fetching Data. 4) State and Props. 5) useState and useEffect.",
        link: "https://www.youtube.com/watch?v=Y6aYx_KKM7A&list=PLEiEAq2VkUUKMie-cEUnkHRxiFKDZJGqO",
      },
      {
        id: 6,
        heading: "Routing in React",
        paragraph:
          "Unlike in HTML, we use a special routing technique, like backend languages to navigate between different pages.",
        link: "https://www.youtube.com/watch?v=Law7wfdg_ls",
      },
    ],
  },
];

export const journals = [
  {
    id: 0,
    intro: "Feeling Alive",
    heading: "Feeling Alive, again.",
    description: [
      {
        paragraph:
          "Not gonna lie man, today I felt alive after such a long long time. Grateful to everyone who supported and helped me. Excited for what's next. A lot of good things coming!",
      },
    ],
    url: "felt-alive",
    date: "17-Nov-22",
    song: "https://youtu.be/9rlW2rUzyn0",
    small: true,
  },
];
