import React from 'react';
import './loader.css'

function Loader(){
    return(
        <div className="loader">
            <div className="circle-of-loader2 ping">
            </div>
            <div className="circle-of-loader">
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/aurang-gif.gif`} alt="loader" /> */}
                    {/* <img src='https://cdn.hammza.com/aurangg/aurangg-gif.gif' alt="loader" /> */}
                    <img src='https://cdn.hammza.com/aurangg/assets/aurang-gif.gif' alt="loader" />
                </div>
        </div>
    )
}

export default Loader;