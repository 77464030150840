import React, { useEffect, useState } from 'react';
import Loader from '../Single/Loader/Loader';


function Ayesha(){
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        setInterval(() => {
            setLoader(true)
        }, 2500);
        document.title  = "Mera Bacha"
    }, [loader])

    if(!loader){
        return <Loader />
    }
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="special">
                        <h1 className="heading">
                            Hey mera bacha!
                        </h1>
                        <p className="paragraph">
                            Missing me, haan behanchod?
                        </p>
                    </div>
                </div>
                <div className="col-lg-8 offset-lg-2">
                    <div className="birthday">
                        <p className="text">
                            Tumne tou dobara yaad nae karna, socha hum he karlain. <br />
                            Anyways, I am very very very fucking proud of you mera bacha. <br />
                            There isn't a day where I don't think about you or bring you home. <br />
                            I don't know how serious you take it, but I am very serious about bringing you home! <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            Just keep going mera bacha, keep going. <br />
                            Just pick the world up and throw it on their fucking head. <br />
                            This wouldn't stay on the website for long, so keep it close. <br />
                            Bhaiyya loves you more than you love bhaiyaa. <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            You are a very bright child and I just don't want you to lose yourself in between. <br />
                            In between these lifeless things that keep happening in your life and mine. <br />
                            But trust me, if you take it positively, you will unbreakable. <br />
                            You can only connect the dots looking backwards! <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            I wrote it for Ushna, and I am only writing it for you now. <br />
                            I don't know why I am doing it, but I felt like doing so yes, fuck it. <br />
                            Bhaiyya will always, always always be here to help, lift, motivate and bring light to your life. <br />
                            Meri jaan ho tum. Mami bhi. <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            I am sorry for leaving like that. I just needed some space for myself. <br />
                            I am still not coming back, but just wanted to say that I miss you so much. <br />
                            My mental health is badly disturbed. I might ask you to get high with me tonight. <br />
                            I hope you will be there. <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            Remember me in your precious prayers. <br />
                            I don't wanna suicide, so pray that things go well with me.<br />
                            I am just letting time pass through so I could come out in peace. <br />
                            I am shifting my priorities, leaving cigerrates and becoming a person I don't wanna become. <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            So if I die, this will be my death note to you. <br />
                            If something happens to me, promise me you will never spoil yourself and<br />
                            keep my dream alive to see you riding in your favorite car. <br />
                            Your bhaiya really wanna hug you. <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            I would still consider Hamza a gandu, no cap. <br />
                            When we printing our book, "Big Facts"?.<br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            Thank you for existing. <br />
                            Thank you for listening to me.<br />
                            Thank you for giving me a shoulder everyday. <br />
                            I won't forget you ever! <br />
                        </p>
                    </div>
                    <div className="birthday">
                        <p className="text">
                            I love you, Ayesha Armughan!<br />
                        </p>
                        <p className="text" style={{opacity:'0.2'}}>
                            Aurang<br />
                        </p>
                    </div>
                    <div className="birthday" style={{textAlign:"center"}}>
                        <a href="https://www.youtube.com/watch?v=xXFdnHiGwos" target="_blank" className="text">
                            Click here!!!!!
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Ayesha;