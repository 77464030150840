import React from 'react';
import SmallHeading from '../Single/SmallHeading/SmallHeading';


function Support(){
    return(
        <div className="container">
            <div className="row">
                <SmallHeading heading="Support" subheading="Buy me a coffee or Macbook" />
                <div className="col-lg-6" style={{display:"flex", justifyContent:'center'}}>
                    <div className="support-main">
                        {/* <img className="support-image" src={`${process.env.PUBLIC_URL}/assets/aurang-gif-2.gif`} alt="auranggif"/> */}
                        <img src='https://cdn.hammza.com/aurangg/assets/aurang-gif-2.gif' alt="auranggif" className="support-image" />
                    </div>
                </div>
                <div className="col-lg-6" style={{display:"flex"}}>
                    <div className="support-text">
                        <h4 className="support-text-main">
                            Help me to do more great stuff by supporting me
                            on Patreon. You can also check out some exclusive writings and posts
                            related to Design, Software Engineering, Upwork and Music.
                        </h4>
                        <a href="https://www.patreon.com/aurang" target="_blank" rel="noopener noreferrer" >
                            <button className="pateron-button" >
                                Support Me On Patreon
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>       
    )
}

export default Support;