import React from 'react';
import { Link } from 'react-router-dom';
import './main-nice.css';
function NiceToMeet(){
    return(
        <div className="main-nice">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Link to="/about">
                            <div className="portfolio-home">
                                <img className="portfolio-home-image phone" src='https://cdn.hammza.com/aurangg/assets/aurang-about-home.webp' alt="menu-icon" />
                                <img className="portfolio-home-image mobile" src='https://cdn.hammza.com/aurangg/assets/aurang-about-m.webp' alt="menu-icon" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NiceToMeet;