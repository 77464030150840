import React, {useEffect, useState} from 'react';
import Footer from '../Footer/Footer';
import PortfolioHome from '../Home/PortfolioHome';
import SectionHeading from '../Single/Button/SectionHeading/SectionHeading';
import Loader from '../Single/Loader/Loader';

function Design(){

    const [loader, setLoader] = useState(false)

    useEffect(() => {
        document.title = "Design | Aurangg"
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])

    if(!loader){
        return(
            <Loader />
        )
    }
    return(
        <React.Fragment>
            <div className="container">
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                    <SectionHeading main="Selected" span="Work" />
                </div>
                <div className="col-2"></div>
                <div className="col-12" style={{marginTop:"10vh"}}>
                    <PortfolioHome />
                </div>
            </div>
        </div>
        <Footer />
        </React.Fragment>
    )
}

export default Design;