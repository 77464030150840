import React from 'react';
import ToggleMenu from '../SideDrawer/ToggleMenu';
import Toggle from '../Dark/Toggle';
import {Link} from 'react-router-dom'
import './toolbar.css';

const toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar-navigation">
            <ToggleMenu click={props.drawerClickHandler} />
            <Link to="/">
                <div className="toolbar-logo">
                    <img src='https://cdn.hammza.com/aurangg/assets/logo.webp' alt="menu-icon" />
                </div>
            </Link>
            <div className="spacer"></div>
            <div className="toolbar-navigation-items">
                <ul>
                    <li>
                        <Link to="/design">
                            Design
                        </Link>
                    </li>
                    <li>
                        <Link to="/development">
                            Dev
                        </Link>
                    </li>
                    <li>
                        <Link to="/about">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
            <Toggle onToggle={props.onToggle}>Toggle</Toggle>
        </nav>
    </header>
)

export default toolbar;