import React from 'react';
import './detailsub.css';

function DetailSubHeading(props){
    return(
        <div className="section-for-description">
            <h3 className="section-for-description-heading">
                {props.name}
            </h3>
            <p className="section-for-description-text">
                {props.description}
            </p>
        </div>
    )
}

export default DetailSubHeading;