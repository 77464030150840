import React, {useEffect, useState} from 'react';
import Loader from '../Single/Loader/Loader';
import './questions.css';
const queries = [
    {
        id:1,
        question:"Teach me the coffee animation man. That is literally my favorite part of the website.",
        answer:"For sure. I will teach you about it. I will also tell you from where to find it. And other resources too that are free to use."
    },
    {
        id:2,
        question:"I like that the scrolling and transition from one part to the next was really smooth. So everything seemed connected.",
        answer:"Yes. That's a front end development thing. Front End development is basically converting Design to Code. So yes. It was a front end thing, but thank you!"
    },
    {
        id:3,
        question:"Minimal text. Loved that. And I absolutely loved the fonttt. Which one is it?",
        answer:"It's Poppins. Before that I was using Montserrat. You can watch both fonts at https://fonts.google.com . All of the fonts here are free for commerical use. I like Poppins because it does not stretch too much in with. It feels squeezed and gives a feeling of harmony and luxury too. I have used the same font in my website. And in most of my designs.",
    },
    {
        id:4,
        question:"The alternative text and the images create a very nice interest. Doesn't make the overall thing look bland.",
        answer:"True. When I was designing it, I had it in my mind that people who know what we are talking about should relate to it. And it should be catchy enough to make us remember for some time. It's a trick to get your attention and it has worked for the most part.",
    },
    {
        id:5,
        question:"Loved the responsive button xD it expands when you move your cursor on it. Makes it come to life.",
        answer:"Yes. These little interactions are important for users to engage with the buttons. It's basically called Hover Effect. The main purpose of hover effect is to notify user that this button/image does some action to some other page. I hope I make sense. Mostly websites/people use a transition to a differnt color. Maybe I have done a similar thing on my website. But since I had my buttons in the center for the homepage, I was able to adjust it's length easily. Agar wo end pay hota tou out of propotions lagna tha."
    },
    {
        id:6,
        question:"Here were a few things that I thought were confusing. Before that, you didn't add a footer. Did you have a reason for not adding that ya awain? :P I hate designing footers, so I can understand.",
        answer:"Footers are usually a summary of your website. Mostly websites are designed in a way jahan header wala sara material, along with contact information and social media information is displayed. Because there must be something that pushes user to interact with more pages. I personally wanted to look clean and minimalist as most of the modern websites have switched towards minimal footers. Also, a footer is in place because we will add our social media pages icons in it, in near future."
    },
    {
        id:7,
        question:"You have website designs and architectural renders and app designs on the home page. With website design, there's are images inside the card and with architectural images as well. But with app design, you have animation (or responsive design). Isn't this sort of an inconsistency?",
        answer:"Nice observation. I didn't notice it till you mentioned it. Han keh lo it's some sort of inconsistency. When I was designing it, then I had an idea for animation of website card as well, but that was going very tricky and I couldn't find it online so I just continued with what I had. Because you know that same perfectionsim syndrome. I prefered to let them alone. But I added some Hover effect to it, to add some life to it. Couldn't think of adding a creative animation to architecture section to be honest."
    },
    {
        id:8,
        question:"When I opened the mobile version, tou the main heading is BIG AND BOLD (Idea of your World. Execution in Ours). I personally love love love big bold headings. On mobile its perfect. But on desktop, I felt it could have been a little bigger.",
        answer:"Yes. I know. Basically the globe animation on the website version wasn't adjusting. I still don't know why. I was trying to make the globe a little smaller, and I tried so many things, but it didn't work. But surprisingly that same globe, becomes so small on the mobile version. Website pay that heading is still readable but you have to adjust the font for mobile to make it more readable. Since globe chota hogya tha tou I had to make the heading bigger and bold since it's the main thing I want everyone to see. Globe is secondary at that time."
    }

]
const portfolio = [
    {
        id:1,
        question:" I like the big bold headings when you open a section of your portfolio. Like graphic design, app development, or stuff.",
        answer:"Yes. Initially like even in the last stages of the deployment of the website and making it live, that wasn't planned. But I felt like showing portfolio directly on a Portfolio Specific Page would be weird. It should address the page or it's purpose in the beginning. Since I was using images from screen one end to the other, I preferred using bold text to create an impact on the user's mind. Like it should struck the user in the first go. So yes, this was added in the last moment and it ended up increasing some of my work for a few more hours. But it was worth it."
    },
    {
        id:2,
        question:"There's a little mistake in thr graphic design portfolio. I'm sure it passed by you :P I'll send a picture",
        answer:"Meine notice kiya tha, but there wasn't much I could do about it. I had to use the same spacing for other heading on their respective pages as well. So I just ignored it and let it be the way it was."
    },
    {
        id:3,
        question:"The architectural renders on the home page. They were not in the portfolio page of Architecture.",
        answer:"Yes true. This is my mistake. So all praise to you for still noticing it. I used those images when I was designing it. So they were in the initial development of the website. But when we started adding data for the website, I missed it and then it was too late for me to update it. Moreover, all of the content on Architectural Visualisation part, is designed in a specific way. Wo part homepage pay adjust nae hona tha. And I was too tired to add Architecutural Visualisation (in Homepage) content in Portfolio Page of it. ",
    },
    {
        id:4,
        question:"The cards on the mobile development portfolio page were really good. I liked those a little better than the one on the homepage.",
        answer:"To be honest, this was such a good observation. I didn't notice it till now that they are different than the one on the homepage. But I guess it works perfect on their respective pages. In Mobile Dev Portfolio Page, I didn't want to use those same animations again. I think it could've felt repeated so I just went with the simple layout like the Website Section on HomePage.",
    },
    {
        id:5,
        question:"You didn't add heading 'Portfolio' on the main portfolio page. So it looks a little lost. Bechara xD",
        answer:"Cool observation. Many people were doubtful about this page. And a lot of people had their reservations on this page. Only people who knew about the functioning of this page, know what to do with it. Most people were confused with it. So this is a must redesign.",
    },
    {
        id:6,
        question:"The cards on the main portfolio page. You designed the whole website so beautifully. It feels jesay you designed this page at the last :P",
        answer:"True. I personally had a lot of issues to manage the user journey for this website. There aren't many this thoughtful software companies websites that I could look into. So yes, I kinda wasnt't focused on it. Plus I don't have a lot of people from whom I could discuss it. So yes, this page sucks. I guess I added icons on last 3/4 days, just to make it look lively than previously simple buttons."
    }
]
const aboutus = [
    {
        id:1,
        question:"REAL COOL COPY",
        answer:"REAL COOL COPY OF WHAT?"
    },
    {
        id:2,
        question:"You developed the company marketing approach right? It shows cause you like Apple and I can see the inspiration.",
        answer:"Yes. I really wanted to put my special attention to this page. I have a personal attachment to my company and what we do against all the odds. Usually software engineers have secured jobs and like crazy salaries. I am not saying that we aren't earning good, but I am just saying that we aren't taken seriously since we don't have fancy events, perks and shashkay as most of our classmates say they enjoy in their respective companies and we aren't taken seriously so I really wanted to put out a statement by designing this page. Like it's declaring a war and I feel like a solider. Like anybody want war? I am excited to start. Get indicted tomorrow and I will be out by the morn, before I even yawn. Like stay in your lane, I remind these lil' boyses. This is a victory lane, now do I need a horn?."
    },
    {
        id:3,
        question:"Acha. I loved this page a lot. Usually companies don't have put this much attention on the team. Although it helps to build team morale.",
        answer:"Yes. I guess my previous answer says the same thing about this question as well."
    },
    {
        id:4,
        question:"There's a little issue of negative space in the value cards. Again. I think this accidentally passed by you so yeah.",
        answer:"I don't think so. I do believe that they are very close to each other can be have some more vertical spacing between them. Can be a good fix. If you visit the website version, you will see Ali Nasir's card on the left with nothing on right. Through code, I could've stretch it to the right side as well, but that could've been such a weird feeling in website verison. You missed it so I thought it's good to mention :P " 
    },
    {
        id:5,
        question:"Loved the Pakistan map. The simple outline. And your location. There were two things. First, Lahore kesi aur font main lekha hai xD Second, I don't like the Pakistan lekha howa at the back. It destroys the focus on the map. Please remove it xD. Just write -Lahore, Pakistan- Samajh jayenge.",
        answer:"Thank you for the map thing. I wanted to show from where we belong. We don't have a fancy office location and people usually showcase their address in their contact page. Humara address koi acha nae hai tou I thought it would be good to come up with something creative. Adding Pakistan in the background was just an attempt to make it fill with something while keeping the minimalist look and feel. I used outlines for the map since that was the best thing to do. Moreover, since you know kay Pakistan ka map aisa hai, we have to deal with clients from all over the world, so I think it was a good idea to add Paksitan, in the background. Moreover, adding Lahore Pakistan wasn't looking nice. Visually speaking. The font is surely different. It's google personal font. I initially planned to use Google's personal font, but it's not allowed to use commerically so I used it in a picture, escaping the algorithm to catch it. xD And the font name is Product Sans - by Google."
    },
]
const aboutusmobile = [
    {
        id:1,
        question:" Acha. In the beginning, you have the heading 'Meet Sinecure' Then 'You dream work. Our team work.' Then the body text. On desktop, the body text and the tag line has the same px, right? The tag line is just bold. On mobile, that's not the case. The body text is smaller than the tag line. And then the tag line is smaller than the title. So it has a lot of variations. And sahi contrast nae ho raha. I hope you got what I meant?",
        answer:"Yes, I understand your concerns over it. Like I said earlier, it's easier to read stuff on the web, but on the mobile, you have to be careful. I was comfortable enough to keep the same font sizes for the tag-line and the body text, but if I was keeping tagline and body text same on the mobile, then due green color surrounding tagline was visually making the text feel small. Since it was our tagline, most people will read it and will scroll over the body text. In the mobile version, the body text is taking a lot of screen so I had to reduce it's size since no one is going to read all of that text, but I wanted them to read out tagline. I hope I able to make you understand what I mean."
    },
    {
        id:2,
        question:"Pakistan nazar he nae aa raha. Bht ghoor se dekhna parhta. Remove kar de'n ussay xD",
        answer:"Haha, I guess with my arguments before, I was able to make a point. If it's trouble noticing it on the phone, I can create another image, which a slighter more visible Pakistan in the background for the mobile version alone. It will stay the same on the web."
    },
    {
        id:3,
        question:"My personal observation",
        answer:"The page title of About is 'Sinecure | Contact', instead of 'Sinecure | About'. Big mistake!!! "
    }
]

const career = [
    {
        id:1,
        question:"Career wala section is perfect. Bus I have a question. The title and the secondary text. They are of the same size. Thori variation nae honi chaheye thi?",
        answer:"Thank you so much. Yes I agree with you on this. We never discussed about adding an open vacany on the website. I just thought of it in last 40 minutes I guess and copy pasted texts from different UI/UX Designers job description and made it a part of the website. Nothing thoughtful. But yes, I will increase font size a bit, add spacing and play with font weights to get it done."
    }
]


function Questions(){

    const [loader, setLoader] = useState(false)
    const {altText, setAltText} = useState('iamge')

    useEffect(() => {
        setInterval(() => {
            setLoader(true)
        }, 2500);
        document.title  = "Questions | Aurangg"
    }, [loader])

    if(!loader){
        return <Loader />
    }
    return(
        <React.Fragment>
            <div className="questions">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="heading-question">
                                Home Page
                            </h2>
                            {queries.map((i => (
                                <div className="question-box" key={i.id}>
                                    <h4 className="question">
                                    {i.id}. {i.question}
                                    </h4>
                                    <h4 className="answer">
                                        {i.answer}
                                    </h4>
                                </div>
                            )))}
                        </div>
                        <div className="col-12">
                            <h2 className="heading-question">
                                Portfolio Page
                            </h2>
                            {portfolio.map((i => (
                                <div className="question-box" key={i.id}>
                                    <h4 className="question">
                                    {i.id}. {i.question}
                                    </h4>
                                    <h4 className="answer">
                                        {i.answer}
                                    </h4>
                                </div>
                            )))}
                        </div>
                        <div className="col-12">
                            <h2 className="heading-question">
                                About Us Page
                            </h2>
                            {aboutus.map((i => (
                                <div className="question-box" key={i.id}>
                                    <h4 className="question">
                                    {i.id}. {i.question}
                                    </h4>
                                    <h4 className="answer">
                                        {i.answer}
                                    </h4>
                                </div>
                            )))}
                        </div>
                        <div className="col-12">
                            <h2 className="heading-question">
                                About Us Mobile Version
                            </h2>
                            {aboutusmobile.map((i => (
                                <div className="question-box" key={i.id}>
                                    <h4 className="question">
                                    {i.id}. {i.question}
                                    </h4>
                                    <h4 className="answer">
                                        {i.answer}
                                    </h4>
                                </div>
                            )))}
                        </div>
                        <div className="col-12">
                            <h2 className="heading-question">
                                Careers Page
                            </h2>
                            {career.map((i => (
                                <div className="question-box" key={i.id}>
                                    <h4 className="question">
                                    {i.id}. {i.question}
                                    </h4>
                                    <h4 className="answer">
                                        {i.answer}
                                    </h4>
                                </div>
                            )))}
                        </div>
                        <div className="col-12">
                            <h2 className="heading-small">
                                Thank you so much for your detailed feedback and spending time on my website from your precious time. 
                                I really really appreciate this effort, so mujay bata dain aap eska khamyaza kya lain gi? :p
                                Still, thank you so much. Let me know when I can help you in your projects and help you push forward in life!
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Questions;