import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    padding: 0;
    margin: 0;
    transition: all 0.25s linear;
  }
  .loader{
    background: ${({ theme }) => theme.body};
  }
  .toolbar{
    background:${({ theme }) => theme.body};
  }
  .side-drawer{
    height:100%;
    position: fixed;
    background:${({ theme }) => theme.body};
    top:0;
    left:0;
    width:70%;
    max-width:400px;
    z-index:1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    }
    .side-drawer a{
      color: ${({ theme }) => theme.text};
      text-decoration: none;
      }
    .side-drawer.open{
    transform: translateX(0px);
    box-shadow: 2px 0px 20px rgba(0,0,0,0.43);
    }
    .toolbar .toolbar-navigation-items a{
      color: ${({ theme }) => theme.text};
    }
  footer {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
  small {
    display: block;
  }
  button {
    display: block;
  }
  a {
    color: ${({ theme }) => theme.text};
  }
`;
