import React from 'react';
import background from '../../Images/background-about.svg'
import SmallHeading from '../../Single/SmallHeading/SmallHeading';

function Friends(){
    return(
        <div className="container container-padding">
            <div className="row">
                <SmallHeading heading="Friends" subheading="The ones who push through thick and thin" />
            </div>
            <div className="row" style={{backgroundImage:`url(https://cdn.hammza.com/aurangg/Images/background-about.svg)`, backgroundPosition:"center", backgroundSize:"cover"}}>
                <div className="col-4">
                    <img src='https://cdn.hammza.com/aurangg/assets/hamza-about.webp' alt="hamza" className="friends-image"  />
                </div>
                <div className="col-4">
                    <img src='https://cdn.hammza.com/aurangg/assets/noor-about.webp' alt="noor" className="friends-image"  />
                </div>
                <div className="col-4">
                    <img src='https://cdn.hammza.com/aurangg/assets/hajji-about.webp' alt="rana" className="friends-image"  />
                </div>
            </div>
        </div>
    )
}

export default Friends;