import React from 'react';
import SmallHeading from '../Single/SmallHeading/SmallHeading';
import './about.css';

function AboutInspiration(){
    return(
        <React.Fragment>
            <SmallHeading heading="Inspirations" subheading="They always come to work in need" />
            <div className="col-lg-6">
                <img src='https://cdn.hammza.com/aurangg/assets/lilwayne-about.webp' alt="lilwayne" className="inspiration"  />
            </div>
            <div className="col-lg-6">
                <img src='https://cdn.hammza.com/aurangg/assets/stevejobs-about.webp' alt="lilwayne" className="inspiration"  />
            </div>
        </React.Fragment>
    )
}

export default AboutInspiration;