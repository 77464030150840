import React from 'react';
import { Link } from 'react-router-dom';

import './heart.css'

function Heart(){
    return(
        <React.Fragment>
            <div className="checkmark-bigger-box">
                <div className="checkmark-box">
                    <img className="heartbeat" src={`${process.env.PUBLIC_URL}/assets/love.svg`} alt="sad"></img>
                    <p className="heartbeat-text">
                        Your email was sent successfully. 
                    </p>
                    <Link to="/">
                        <div className="flex-container">
                            <button className="button-crud">
                                Okay
                            </button>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Heart;