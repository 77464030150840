import React from "react";

function Music({ i }) {
  return (
    <a className="music" target="_blank" href={i?.song}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.718"
        height="15.228"
        viewBox="0 0 11.718 15.228"
      >
        <path
          id="Icon_ionic-ios-musical-notes"
          data-name="Icon ionic-ios-musical-notes"
          d="M18.109,3.382,10.561,4.847a.325.325,0,0,0-.3.293v8.789a1.01,1.01,0,0,1-.088.428.975.975,0,0,1-.589.465c-.121.04-.286.077-.48.121-.882.2-2.357.534-2.357,1.9a1.676,1.676,0,0,0,1.527,1.739,1.785,1.785,0,0,0,.26.026h0a4.518,4.518,0,0,0,1.874-.483,1.972,1.972,0,0,0,.882-1.75V8.592a.29.29,0,0,1,.234-.286l5.564-1.124a.293.293,0,0,1,.351.286V12.26a1.049,1.049,0,0,1-.092.491.973.973,0,0,1-.593.465c-.121.04-.322.077-.516.121-.882.2-2.357.531-2.357,1.893A1.679,1.679,0,0,0,15.415,17a3.025,3.025,0,0,0,.71-.033,4.2,4.2,0,0,0,1.336-.476,1.93,1.93,0,0,0,1.007-1.764V3.664A.29.29,0,0,0,18.109,3.382Z"
          transform="translate(-6.75 -3.375)"
          fill="#bbb"
        />
      </svg>
    </a>
  );
}

export default Music;
