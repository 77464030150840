import React from 'react';
import './small-heading.css';

function SmallHeading(props){
    return(
        <div className="col-12">
            <h2 className="small-heading">
                {props.heading}
            </h2>
            <p className="small-sub-heading">
                {props.subheading}
            </p>
        </div>
    )
}
export default SmallHeading;