import React, {useState, useEffect} from 'react';
import Footer from '../Footer/Footer';
import './contact.css'
import Loader from '../Single/Loader/Loader';
import Heart from '../Single/Heart';
import SectionHeading from '../Single/Button/SectionHeading/SectionHeading';

function Contact(){
    const [name, setName] = useState('');
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const apikey = "100573f2-0285-41f5-bf33-ccadd0d701c6"
    const url = "https://api.web3forms.com/submit"

    const handleMessage = (e) => {
        setMessage(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleName = (e) => {
        setName(e.target.value)
    }

    useEffect(() => {
        document.title = "Contact | Aurangg"
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])

    const overlayFunctionOn = () => {
        if((name.length === 0) || (email.length === 0) || (message.length === 0)){
            return true;
        }
        else{
            document.getElementById("form-overlay").style.display = "block"
            document.getElementById("loader").style.display = "flex"
        }
    }
    const overlayFunctionOff = () => {
        document.getElementById("form-overlay").style.display = "none"
    }
    const checkBoxFunction = () => {
        document.getElementById("loader").style.display = "none"
        document.getElementById("success").style.display = "block"
    }
    const SubmitForm = async(e) => {
        e.preventDefault();
        const headers = {
            method:'POST',
            body:JSON.stringify({ name, email, message, apikey}),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        }
        const membersData = await fetch(url,headers)
        const data = await membersData.json();
        if(data.success === true){
            return(
                <React.Fragment>
                    {checkBoxFunction()}
                </React.Fragment>
            )
        }
    }
    if(!loader){
        return <Loader />
    }
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="contact">
                            <SectionHeading main="Get In" span="Touch" />
                            <div id="form-overlay" onClick={overlayFunctionOff}>
                                <div className="loader" id="loader" style={{display:"none"}}>
                                    <div className="loader-mini">
                                    </div>
                                </div>
                                <div style={{display:"none"}} id="success">
                                        <Heart />
                                </div>
                            </div>
                            <form onSubmit={SubmitForm} >
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputPassword4">What is your name?</label>
                                        <input 
                                            type="name" 
                                            name="name"
                                            value={name}
                                            className="form-control"
                                            onChange={handleName}
                                            placeholder="Enter Your Name"
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputPassword4">What is your email?</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={email}
                                            className="form-control"
                                            onChange={handleEmail}
                                            placeholder="Enter Your Email"
                                            required 
                                        />
                                    </div>
                                    
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputPassword4">Your message</label>
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            value={message}
                                            onChange={handleMessage}
                                            placeholder="Enter message"
                                            rows="5"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="flex-start">
                                    <button className="button-submit" type="submit" onClick={overlayFunctionOn}>Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Contact;