export const lightTheme = {
    name: "light",
    text: "#363537",
    body: "#fff"
  };

export const darkThemeFooter={
    name:'dark',
    text:"#6bfad2",
    body:"#1c1c1c"
}

export const lightThemeFooter={
    body:"#f5f5f5",
    text:"#222222",
    name:"lightFooter"
}
  
  export const darkTheme = {
    name: "darkFooter",
    text: "#FAFAFA",
    body: "#131313"
  };
  