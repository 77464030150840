import React from 'react';
import {design} from '../Single/Single';
import {Link} from 'react-router-dom';

function PortfolioHome(){
    const prop = design
    return(
        <div className="container">
            <div className="row">
                {prop.map((i => (
                    <React.Fragment key={i.id}>
                        <Link to={{
                            pathname:`/design/${i.name}`,
                            state:i.color
                        }}>
                            <div className="col-12 special">
                                <div className="portfolio-home">
                                    <img className="portfolio-home-image" src={i.image} alt="menu-icon" />
                                </div>
                            </div>
                        </Link>
                    </React.Fragment>
                ))).reverse()}
            </div>
        </div>
    )
}
export default PortfolioHome;