import React from "react";

function Paragraph({ i }) {
  return (
    <>
      {i?.description?.map((j, index) => (
        <p key={index} className="journal-description">
          {j?.paragraph}
        </p>
      ))}
    </>
  );
}

export default Paragraph;
