import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Heading from "../../Single/Heading";
import { journals } from "../../Single/WorkDetailFile";

function JournalHome() {
  const data = journals;
  useEffect(() => {
    document.title = `Journals | Aurangg`;
  });
  return (
    <section className="">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Heading text="Journals" />
            <p className="journals-sub-heading">Out of context Aurang</p>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "60px" }}>
          {data?.map((i) => (
            <div className="col-lg-4" key={i?.id}>
              <Link to={`/journals/${i?.url}`}>
                <div className="journal-tile">
                  <h2 className="tile-heading">{i.id + 1}</h2>
                  <p className="tile-sub-heading">{i?.intro}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default JournalHome;
