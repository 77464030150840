import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Home/Home";
import Toolbar from "./Components/Toolbar/Toolbar";
import SideDrawer from "./Components/SideDrawer/SideDrawer";
import Backdrop from "./Components/BackDrop/Backdrop";
import { useDarkMode } from "./Components/Dark/DarkMode";
import { lightTheme, darkTheme } from "./Components/Dark/Theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./Components/Dark/Global";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Contact from "./Components/Contact/Contact";
import About from "./Components/About/About";
import Design from "./Components/Design/Design";
import Dev from "./Components/Dev/Dev";
import DesignDetail from "./Components/Design/DesignDetail/DesignDetail";
import DevDetail from "./Components/Dev/DevDetail/DevDetail";
import Loader from "./Components/Single/Loader/Loader";
import Questions from "./Components/Questions/Questions";
import BlogMain from "./Components/Blogs/BlogMain";
import BlogDetail from "./Components/Blogs/BlogDetail";
import Ayesha from "./Components/Personal/Ayesha";
import Timer from "./Components/Timer/Timer";
import BusinessDev from "./Components/Research/BusinessDev";
import JournalHome from "./Components/Journals/JournalsHome/JournalHome";
import JournalDetail from "./Components/Journals/JournalDetail/JournalDetail";
// import FlappyBird from './Components/Game/Game'
// import Game2 from './Components/Game/Game2';
function App() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  function drawerToggleClickHandler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }
  function backdropClickHandler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }
  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  const [theme, toggleTheme] = useDarkMode("light");

  return (
    <React.Fragment>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Router>
          <Toolbar
            drawerClickHandler={drawerToggleClickHandler}
            onToggle={toggleTheme}
          />
          <SideDrawer
            show={sideDrawerOpen}
            drawerClickHandler={drawerToggleClickHandler}
          />
          {backdrop}
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/loader" exact component={Loader} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/about" exact component={About} />
            <Route path="/theotherayesha" exact component={Questions} />
            <Route path="/design" exact component={Design} />
            <Route path="/design/:id" component={DesignDetail} />
            <Route path="/development" exact component={Dev} />
            <Route path="/development/:id" component={DevDetail} />
            <Route path="/blogs" exact component={BlogMain} />
            <Route path="/blogs/:id" component={BlogDetail} />
            <Route path="/lilayesha" exact component={Ayesha} />
            <Route path="/birthday" exact component={Timer} />
            <Route path="/bd" exact component={BusinessDev} />
            <Route path="/journals" exact component={JournalHome} />
            <Route path="/journals/:id" component={JournalDetail} />
          </Switch>
        </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
