import React, {useEffect, useState} from 'react';


function Timer() {
    const [counter, setCounter] = useState(60);
    var countDownDate = new Date("August 1, 2021 00:00:00").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
  document.getElementById("demo").innerHTML = days + "d " + hours + "h "
  + minutes + "m " + seconds + "s ";

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("demo").innerHTML = `Happy birthday Hamza, I hope you're doing well. <br>Kaarna bohat kuch tha laken ab itna he kaafi hai. <br>I hope you have a wonderful birthday and I hope years coming ahead will bring all the happiness and joy in your life. <br>Stay healthy stay safe. <br>I planned a lot more for your birthday, I thought I should call you and I even prepared a pdf and so much more gifts type things jo pehlay kabhi nahi kiya meine, but since you didn't seem to care much about anything so I won't send you the pdf nor I will call you or anything else at all. <br>Have a good life, enjoy your day to the fullest. <br><br> And also thanks to bhaiyya for letting me use his website!`
  }
}, 1000);
  
    return (
      <React.Fragment>
          <div className="questions">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-8 offset-lg-2">
                      <h2 className="heading-question">
                                For Hamza Malik
                            </h2>
                        <div className="question-box">
                            <h4 className="answer" id="demo">
                            </h4>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </React.Fragment>
    );
  }

  export default Timer;
  