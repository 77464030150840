import React from 'react';
import './section-heading.css'

function SectionHeading(props){
    return(
        <h1 className="section-heading">
            {props.main} <span className="section-heading-span">{props.span}</span>
        </h1>
    )
}

export default SectionHeading;