import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../Single/Heading";
import { journals } from "../../Single/WorkDetailFile";
import Music from "./Music";
import Paragraph from "./Paragraphs";

function JournalDetail() {
  const url = window.location.pathname.slice(10);
  const filteredData = journals;
  const res = filteredData.filter((i) => i?.url === url);

  useEffect(() => {
    document.title = `${res[0]?.intro} | Aurangg`;
  });

  return (
    <>
      {res?.map((i, index) => (
        <section
          className={`flex-container flex-direction-col ${
            i?.small === true ? "height-100vh" : ""
          }`}
          key="index"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <p className="journals-sub-heading">{i?.heading}</p>
                <p className="journal-date">{i?.date}</p>
              </div>
            </div>
            <div className="row" style={{ paddingTop: "60px" }}>
              <div className="col-lg-8 offset-lg-2">
                <Paragraph i={i} />
                <Music i={i} />
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
}

export default JournalDetail;
