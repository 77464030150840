import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import Loader from '../Single/Loader/Loader';
import {blogs} from '../Single/WorkDetailFile';
import './blogs.css';

function BlogDetail(props){
    const prop = props.match.params.id
    const idForBlog = parseInt(prop)
    const [loader, setLoader] = useState(false)
    
    useEffect(() => {
        document.title = `Blogs | Aurangg`
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])

    if(!loader){
        return <Loader />
    }
    function checkBlog(blogs){
        const result = blogs.filter(function(d){
            return(
                d.id === idForBlog
            )
        })
        console.log(result)
        if(result.length === 0){
            return(
                <p>
                    False
                </p>
            )
        }
        else{
            return(
                <div className="blog-detail">
                    <h1 className="blog-detail-heading">
                        {result[0].title}
                    </h1>
                    <p className="blog-detail-description">
                        {result[0].description}
                    </p>
                    {result[0].headingAndParagraph.map((i => (
                        <div className="blog-box" key={i.id}>
                            <h3 className="blog-box-heading">
                                {i.heading}
                            </h3>
                            <p className="blog-box-description">
                                {i.paragraph}
                            </p>
                            <a href={i.link} target="_blank" className="blog-box-link">
                                {i.link}
                            </a>
                        </div>
                    )))}
                </div>
            )
        }
    }
    return(
        <React.Fragment>
            <div className="container">
            <div className="row">
                <div className="col-12">
                    {checkBlog(blogs)}
                </div>
            </div>
        </div>
            <Footer />
        </React.Fragment>
    )
}
export default BlogDetail;