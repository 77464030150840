import React from "react";
import { data } from "./data";

function List(props) {
  const filteredData = data.filter((el) => {
    if (props.input === "") {
      return el;
    } else {
      return el?.name.toLowerCase().includes(props.input);
      //   return el?.tags?.filter((i) =>
      //     i?.text.toLowerCase().includes(props.input)
      //   );
    }
  });
  return (
    <>
      {filteredData.map((i) => (
        <div className="link-box" key={i?.id}>
          <div className="link-flex-end">
            <a href={i?.url} target="_blank">
              {i?.url}
            </a>
            <div className="d-flex">
              {i?.isDesign === true ? (
                <div className="service-box">
                  <p className="service-text">UI/UX Design</p>
                </div>
              ) : (
                <></>
              )}
              {i?.isDevelopment === true ? (
                <div className="service-box">
                  <p className="service-text">Front End</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="items-box">
            {i?.tags.map((j, index) => (
              <li className="items" key={index}>
                <span className="items-pill">{j?.text}</span>
              </li>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default List;
