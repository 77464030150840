import React, { useState } from "react";
import Heading from "../Single/Heading";
import List from "./List";
import Search from "./Search";

function BusinessDev() {
  const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <Heading text="Portfolio Links" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <Search inputHandler={inputHandler} />
          </div>
        </div>
        <div className="row" style={{ marginTop: "50px" }}>
          <div className="col-lg-10 offset-lg-1" id="table">
            <List input={inputText} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BusinessDev;
