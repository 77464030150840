import React, { useEffect, useState } from 'react';
import SectionHeading from '../Single/Button/SectionHeading/SectionHeading';
import Loader from '../Single/Loader/Loader';
import BlogsPreview from './BlogsPreview';


function BlogMain(){
    const [loader, setLoader] = useState(false)
    
    useEffect(() => {
        document.title = "Blogs | Aurangg"
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])

    if(!loader){
        return <Loader />
    }
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10" style={{marginBottom:"40px"}}>
                    <SectionHeading main="My" span="Blogs!" />
                </div>
                <div className="col-lg-1"></div>
            </div>
            <div className="row">
                <BlogsPreview />
            </div>
        </div>
    )
}

export default BlogMain;