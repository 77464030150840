import React from 'react';
import SmallHeading from '../Single/SmallHeading/SmallHeading';


function Goals(){
    return(
        <div className="container container-padding">
            <div className="row">
                <SmallHeading heading="Goals" subheading="For 2021" />
            </div>
            <div className="row">
               <div className="col-lg-4 offset-lg-4">
                   <p className="goals">
                        People y'all know me
                        <br />
                        This ain't nothing but practice
                        <br />
                        OG, I been sonnin' these actors
                        <br />
                        Mountain to climb
                        <br />
                        Got a vision for that thing
                        <br />
                        And if y'all don't see
                        <br />
                        Then I'm coming with Graphics
                        <br />
                        People, war, no peace.
                        <br />
                        And I'm coming with caskets
                        <br />
                        Screaming RIP
                        <br />
                        to everyone in this freelance thing
                        <br />
                        People y'all so sweet
                        <br />
                        And I'm nothing but nasty
                        <br />
                        And I'll be moving this Design thing
                        <br />
                        Like Michael Jackson
                        <br />
                        No Ceilings.
                    </p>
                    <p style={{marginTop:"60px"}} className="goals">
                        Special shoutout to my sister. Thank you.
                    </p>
               </div>
            </div>
        </div>
    )
}

export default Goals;