import React, {useEffect, useState} from 'react';
import Footer from '../Footer/Footer';
import SectionHeading from '../Single/Button/SectionHeading/SectionHeading';
import Loader from '../Single/Loader/Loader';
import './about.css';
import AboutInspiration from './AboutInspiration';
import Friends from './Friends/Friends';
import Goals from './Goals';
import Support from './Support';


function About(){

    const [loader, setLoader] = useState(false)
    
    useEffect(() => {
        document.title = "About | Aurangg"
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])

    if(!loader){
        return <Loader />
    }
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <SectionHeading main="Meet," span="Aurangg" />
                        <div className="about-image">
                            <img src='https://cdn.hammza.com/aurangg/assets/aurang-about2.webp' alt="aurang-about2" />
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-8 offset-lg-2">
                        <p className="about-paragraph">
                            My name is Aurangzeb Khan. I'm a Digital Designer and Front End Developer from Pakistan. I am currently working at Upwork as a UI/UX Designer. I love to work accessible User Interfaces and minimized User Experiences, to make people fall in love with the product. I highly rely on good tea and good music. During me time, I spend time on social media to keep learning new stuff and challenge myself with a new thing. 
                        </p>
                    </div>
                    <AboutInspiration />
                </div>
            </div>
            <Friends />
            <Support />
            <Goals />
            <Footer />
        </React.Fragment>
    )
}

export default About;