import React, {useEffect, useState} from 'react';
import Banner from './Banner';
import './home.css';
import DesignHome from './DesignHome';
import PortfolioHome from './PortfolioHome';
import PortfolioHomeFront from './PortfolioHomeFront.js';
import FrontEndHome from './FrontEndHome';
import Footer from '../Footer/Footer';
import NiceToMeet from './NiceToMeet';
// import NiceToMeetYou from './NiceToMeetYou';
import AboutMeHome from './AboutMeHome';
import AboutMeHeading from './AboutMeHeading';
import Loader from '../Single/Loader/Loader';

function Home(){
    const [loader, setLoader]= useState(false)
    useEffect(() => {
        
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])
    if(!loader){
        return <Loader />
    }
    return(
        <React.Fragment>
            <Banner />
            {/* <AboutMeHeading /> */}
            <NiceToMeet />
            {/* <NiceToMeetYou /> */}
            {/* <AboutMeHome></AboutMeHome> */}
            <DesignHome />
            <PortfolioHome />
            <FrontEndHome />
            <PortfolioHomeFront />
            <Footer />
        </React.Fragment>
    )
}

export default Home;