import React, { useState } from "react";

function Search({ inputHandler }) {
  return (
    <input
      type="text"
      className="search-field"
      id="search"
      onChange={inputHandler}
    />
  );
}
export default Search;
