import React from 'react';
import './blogs.css';
import {blogs} from '../Single/WorkDetailFile';
import { Link } from 'react-router-dom';

function BlogsPreview(){
    console.log(blogs)
    return(
        <div className="col-lg-8 offset-lg-2">
            {blogs.map((i => (
                <Link to={{
                    pathname:`/blogs/${i.id}`,
                }} key={i.id}>
                    <div className="blogs-preview">
                        <h3 className="blogs-preview-heading">
                            {i.title}
                        </h3>
                        <div className="flex-end-main">
                            <p className="blog-date">
                                {i.date}
                            </p>
                        </div>
                    </div>
                </Link>
            )))}
        </div>
    )
}

export default BlogsPreview;