import React, {useEffect, useState} from 'react';
import Footer from '../../Footer/Footer'
import {design} from '../../Single/WorkDetailFile';
import './detail.css'
import DetailHeading from '../../Single/DetailHeading/DetailHeading';
import DetailSubHeading from '../../Single/DetailSub/DetailSubHeading';
import Loader from '../../Single/Loader/Loader';

function DesignDetail({location}){
    const [loader, setLoader] = useState(false)
    const name = location.pathname.slice(8)
    const final = name.charAt(0).toUpperCase() + name.slice(1);
    useEffect(() => {
        document.title = `${final} |  Aurangg`
        setTimeout(() => {
            setLoader(true)
        }, 2500);
    }, [loader, final])
    function checkProject(design, location){
        // const link = location.pathname.slice(8)
        console.log(design)
        const result = design.filter(function(d){
            return(
                d.url === name
            )
        })
        if(result.length === 0){
            return(
                <p>
                    Sorrry
                </p>
            )
        }
        else{
            return(
                <React.Fragment>
                    <div className="container container-special" style={{background:result[0].color}}>
                        <div className="row" style={{paddingTop:"10vh"}}>
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <DetailHeading name={result[0].name} description={result[0].nameDescription} />
                                <img src={result[0].mainImage} alt="monezen" style={{padding:"100px 0px 0px 0px"}} /> 
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <DetailSubHeading name="Description" description={result[0].goal} />
                            </div>
                            {checkProjectName(result[0])}
                            <div className="col-lg-8 offset-lg-2">
                                <DetailSubHeading name="Visual Design" description={result[0].description} />
                            </div>
                        </div>
                    </div>
                    <div className="container container-special" style={{marginTop:"0px"}}>
                        <div className="row">
                            <div className="col-12" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                                <img src={result[0].visualDesign} alt="moneezen" className="visual-design" /> 
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <DetailSubHeading name="Design Process & Execution" description={result[0].designprocess} />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6" style={{paddingRight:"8px", paddingLeft:"8px"}}>
                                <img src={result[0].progressDesign1} alt="moneezen" className="visual-design-1" /> 
                                <img src={result[0].progressDesign3} alt="moneezen" className="visual-design-3" /> 
                            </div>
                            <div className="col-lg-6" style={{paddingRight:"8px", paddingLeft:"8px"}}>
                                <img src={result[0].progressDesign2} alt="moneezen" className="visual-design-2" /> 
                            </div>
                            <div className="col-lg-8 offset-lg-2">
                                <DetailSubHeading name="Conclusion" description={result[0].conclusion} />
                            </div>
                            <div className="col-lg-8 offset-lg-2">
                                <div className="image-center">
                                    <img src={result[0].technologyImage} alt="moneezen" className="technology-design" />
                                </div> 
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        
    }
    function checkProjectName(result){
        if(result.name.toLowerCase() === "nyc app"){
            return(
                <React.Fragment>
                    <div className="col-6" style={{textAlign:"center"}}>
                        <img src={result.secondImage} alt="moneezen" className="phone-one" /> 
                    </div>
                    <div className="col-6" style={{textAlign:"center"}}>
                        <img src={result.thirdImage} alt="moneezen" className="phone-two" /> 
                    </div>
                </React.Fragment>
            )
        }
        else{
            return(
                <div className="col-lg-8 offset-lg-2" style={{textAlign:"center"}}>
                    <img src={result.secondImage} alt="moneezen" className="ipad-one" /> 
                    <img src={result.thirdImage} alt="moneezen" className="ipad-two" /> 
                </div>
            )
        }

    }
    if(!loader){
        return <Loader />
    }
    return(
        <React.Fragment>
            {checkProject(design, location)}
            <Footer />
        </React.Fragment>
    )
}

export default DesignDetail;